import { Product } from './types';
import Button from '../Button';
import { CartItem } from '../CartList';
import CountPicker from '../CountPicker';
import CartModule from '../Support/CartModule';
import { SaveLifeControllerService } from '@/__generated__/FrontApi';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import GtmHelper from '@/helpers/GtmHelper';
import { SUPPORT_TERM_ONCE } from '@/helpers/SupportHelper';
import { navigate } from 'gatsby';
import React, { FC, useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

const PrdInfo = styled.div`
  width: 100%;
  padding-top: 25px;
  padding-bottom: 120px;
  border-top: 1px solid #e5e6e8;

  ${breakpoint(`tablet`)} {
    padding-bottom: 40px;
  }

  .cate {
    color: #1cabe2;
    display: block;
    margin-bottom: 10px;
  }

  .price {
    margin-top: 50px;
    display: block;

    ${breakpoint(1160)} {
      margin-top: 4.31vw;
    }

    ${breakpoint(`tablet`)} {
      margin-top: 24px;
    }
  }

  .cnt-wrap {
    margin-top: 20px;
  }
`;
const PrdBtt = styled.div`
  width: 100%;
`;

const TotAmount = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 25px;
  border-top: 1px solid #e5e6e8;
`;

const BtnFlex = styled.ul`
  display: flex;
  margin: 0 -18px;
  margin-top: 46px;

  ${breakpoint(1160)} {
    margin-top: 3.97vw;
  }
  li {
    width: 50%;
    padding: 0 18px;
  }
`;

const H2Title = styled.h2`
  display: inline-block;
  line-height: 1.56;
  letter-spacing: -0.8px;
  font-size: 32px;
  ${breakpoint(1160)} {
    font-size: 2.76vw;
  }
  ${breakpoint(`mobile`)} {
    font-size: 20px;
  }
`;

export interface PrdInformationFormData {
  count: number;
}
interface ProductPageProps {
  detail: Product;
}

export const PrdInformation: FC<ProductPageProps> = ({ detail }) => {
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);
  const [count, setCount] = useState(1);
  const totalPrice = useMemo(() => detail.productPrice * count, [
    count,
    detail.productPrice,
  ]);

  const addToCart = useCallback(async () => {
    const { resultCode } = await SaveLifeControllerService.addGiftCartUsingPost(
      {
        productCode: detail.productCode,
        count: `${count}`,
      },
    );
    if (resultCode === `success`) {
      // 모달 열기
      setIsCartModalOpen(true);

      // gtm 처리
      GtmHelper.addToCart({
        value: (detail.productPrice * count).toString(),
        itemId: detail.productCode,
        itemName: detail.productName,
        itemCategory: SUPPORT_TERM_ONCE,
        itemListName: detail.categoryCodeName,
        price: detail.productPrice.toString(),
        quantity: count.toString(),
      });
    }
  }, [
    count,
    detail.categoryCodeName,
    detail.productCode,
    detail.productName,
    detail.productPrice,
  ]);

  const navigateToForm = useCallback(() => {
    const item: CartItem = {
      count,
      productCode: detail.productCode,
      productName: detail.productName,
      productNameEn: detail.productNameEn,
      productPrice: detail.productPrice,
      categoryCodeName: detail.categoryCodeName,
      imgProductAttImgNo1_50: detail.imgProductAttImgNo1_50,
      imgProductAttImgNo1_140: detail.imgProductAttImgNo1_140,
      imgProductAttImgNo1_290: detail.imgProductAttImgNo1_290,
      imgProductAttImgNo1_420: detail.imgProductAttImgNo1_420,
      basketUserId: ``,
      firstRegisterDate: ``,
      sumPrice: ``,
    };
    navigate(`/involve/individual/ig/form/`, {
      state: {
        items: [item],
      },
    });
  }, [count, detail]);

  return (
    <>
      <PrdInfo>
        <strong className="cate">{detail.categoryCodeName}</strong>
        <Tit size="s2">
          <H2Title>
            {detail.productName}
            <br />
            {detail.productNameEn}
          </H2Title>
        </Tit>
        <Tit size="s1-5" weight="normal" className="price">
          {detail.productPrice.toLocaleString()}
          {` `}
          <Tit size="s4">원</Tit>
        </Tit>
        <div className="cnt-wrap">
          <CountPicker
            name="count"
            min={1}
            max={999}
            value={count}
            onChange={(value) => {
              setCount(value);
            }}
          />
        </div>
      </PrdInfo>
      <PrdBtt>
        <TotAmount>
          <Tit size="s4">후원금액</Tit>
          <Tit size="s2" color="sky">
            {totalPrice.toLocaleString()} 원({count}개)
          </Tit>
        </TotAmount>
        <BtnFlex>
          <li>
            <Button onClick={() => addToCart()} outline full>
              장바구니
            </Button>
            {isCartModalOpen && (
              <CartModule
                isOpen
                onRequestClose={() => setIsCartModalOpen(false)}
              />
            )}
          </li>
          <li>
            <Button size="md" shape="square" full onClick={navigateToForm}>
              후원하기
            </Button>
          </li>
        </BtnFlex>
      </PrdBtt>
    </>
  );
};
export default PrdInformation;
